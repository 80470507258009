.tagStyle {
  --tags-border-color: none;
  --tag-bg: #ff008e;
  --tag-hover: #0e5a7b;
  --tag-text-color: #ffffff;
  --tag-remove-btn-color: #ffffff;
  --tag-text-color: #ffffff;
  --placeholder-color: #525252;
  --tagify-dd-color-primary: #000000;
  --input-color: #0e5a7b;
  --tagify-dd-color-primary: none;
}

.tagStyle .tagify__dropdown__wrapper {
  border: none;
  background: white;
  margin: 0.2em;
  color: #0e5a7b;
  box-shadow: none;
}

.tagStyle .tagify__dropdown__item {
  border: none;
  color: #0e5a7b;
  margin: 0.2em;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tagStyle .tagify__dropdown__item:hover {
  border: none;
  color: #ff008e;
  background: #0e5a7b;
  margin: 0.2em;
}
