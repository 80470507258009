@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-btn {
    @apply my-2 text-abx-dark-blue hover:text-abx-pink;
  }
  ,
  .link {
    @apply my-2 text-abx-dark-blue hover:text-abx-pink;
  }
  ,
  .pink-link {
    @apply my-2 text-abx-pink hover:text-abx-blue;
  }
  ,
  .pink-underline-link {
    @apply my-2 text-abx-pink hover:underline;
  }
  ,
  .link-white {
    @apply my-2 text-white hover:text-abx-pink;
  }
  ,
  .btn-underline {
    @apply bg-none pb-1 pt-2 pr-4 text-abx-dark-blue border-b border-abx-light-blue hover:border-abx-dark-blue;
  }
  ,
  .btn-underline-entered {
    @apply bg-none pb-1 pt-2 pr-4 text-abx-dark-blue border-b border-abx-dark-blue;
  }
  ,
  .btn-underline-error {
    @apply bg-none pb-1 pt-2 pr-4 text-abx-error border-b-2 border-abx-error;
  }
  ,
  .btn-dark-blue {
    @apply bg-abx-dark-blue hover:bg-abx-pink p-3 rounded text-white hover:text-white border border-abx-dark-blue hover:border-abx-pink;
  }
  ,
  .btn-blue {
    @apply bg-abx-blue hover:bg-abx-pink p-3 rounded text-white hover:text-white border border-abx-blue hover:border-abx-pink;
  }
  .btn-disabled {
    @apply bg-abx-light-grey p-3 rounded text-abx-dark-grey border border-abx-light-grey opacity-60;
  }
  ,
  .btn-light-blue {
    @apply bg-abx-light-blue hover:bg-abx-pink p-3 rounded text-abx-dark-blue hover:text-white border border-abx-light-blue hover:border-abx-pink;
  }
  ,
  .btn-orange {
    @apply bg-abx-orange hover:bg-abx-pink p-3 rounded text-white border border-abx-orange hover:border-abx-pink;
  }
  ,
  .btn-pink {
    @apply bg-abx-pink hover:bg-abx-orange p-3 rounded text-white border border-abx-pink hover:border-abx-orange;
  }
  ,
  .btn-outline-white {
    @apply bg-transparent hover:bg-abx-pink py-3 px-4 rounded text-white border border-white hover:border-transparent;
  }
  ,
  .btn-outline-blue {
    @apply bg-transparent hover:bg-abx-pink p-3 rounded text-abx-dark-blue border border-abx-dark-blue hover:border-transparent hover:text-white;
  }
  .btn-outline-mid-blue {
    @apply bg-transparent hover:bg-abx-pink p-3 rounded text-abx-blue border border-abx-blue hover:border-transparent hover:text-white;
  }
  ,
  button {
    @apply focus:outline-none;
  }

  ,
  h5 {
    @apply text-abx-h5 font-medium;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  z-index: 1500;
}

.tippy-box[data-theme~='tomato'] {
  background-color: tomato;
  color: yellow;
}
