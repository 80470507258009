.tippy-box[data-theme~='tomato'] {
    background-color: tomato;
    color: yellow;
  }

.tooltip {
  background-color: #525252 !important;
  color: #fff;
  padding: 2px;
  opacity: 0.9;
}