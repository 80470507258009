/* Min width forced to 350px could not set to 100% in filter of analysis, may cause responsiveness issues */
.MultiTagStyle {
  --tags-border-color: none;
  --tag-bg: #ff008e;
  --tag-hover: #0e5a7b;
  --tag-text-color: #ffffff;
  --tag-remove-btn-color: #ffffff;
  --tag-text-color: #ffffff;
  --placeholder-color: #ffffff;
  --tagify-dd-color-primary: #000000;
  --input-color: #0e5a7b;
  --tagify-dd-color-primary: none;
  width: 350px;
  /*background-color: blue;*/
  /*min-width: 350px;
  margin: 0;
  width: 100%;
  height: 100%;*/
}

.MultiTagStyle:hover {
  --placeholder-color: #0d8abc;
}

.MultiTagStyle .tagify__dropdown__wrapper {
  border: none;
  background: white;
  margin: 0.2em;
  color: #0e5a7b;
  box-shadow: none;
}

.MultiTagStyle .tagify__dropdown__item {
  border: none;
  color: #0e5a7b;
  margin: 0.2em;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MultiTagStyle .tagify__dropdown__item:hover {
  border: none;
  color: #ff008e;
  background: #daeaf6;
  margin: 0.2em;
}
